import Cookies from "universal-cookie";

export const API_WEBSITE = process.env.REACT_APP_API_URL;
export let TENANT_BASE = "";
export const BASE_URL = "/wp-json/fitcopilot/v1";
export const AUTHENTICATION_BASE = `${API_WEBSITE}/api/users`;
export let DASHBOARD_BASE = `${TENANT_BASE}/api/dashboard`;

export function getUserHeader() {
  return `UserToken ${get_remote_cookie()}`;
}

export function getCookie(name: string): string | undefined {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);

  if (parts.length === 2) {
    return parts.pop()?.split(";").shift(); // Use optional chaining to avoid TypeError
  }

  return undefined; // Return undefined if cookie is not found
}

export function set_jwt_cookie(jwt: string) {
  let cookies = new Cookies();
  cookies.set("jwt_auth_token", jwt);
}

export function get_jwt_cookie() {
  let cookies = new Cookies();
  return cookies.get("jwt_auth_token");
}

export function delete_jwt_cookie() {
  let cookies = new Cookies();
  cookies.remove("jwt_auth_token");
}

export function set_remote_cookie(token: string) {
  let cookies = new Cookies();
  cookies.set("remote_cookie", token);
}

export function get_remote_cookie() {
  let cookies = new Cookies();
  return cookies.get("remote_cookie");
}

export function delete_remote_cookie() {
  let cookies = new Cookies();
  cookies.remove("remote_cookie");
}

export function set_jwt_access_token(token: string) {
  let cookies = new Cookies();
  cookies.set("jwt_access_token", token);
}

export function set_jwt_refresh_token(token: string) {
  let cookies = new Cookies();
  cookies.set("jwt_refresh_token", token);
}

export function get_jwt_access_token() {
  let cookies = new Cookies();
  return cookies.get("jwt_access_token");
}

export function get_jwt_refresh_token() {
  let cookies = new Cookies();
  return cookies.get("jwt_refresh_token");
}

export function delete_jwt_cookies() {
  let cookies = new Cookies();
  cookies.remove("jwt_refresh_token");
  cookies.remove("jwt_access_token");
}

export const getAuthorizationHeader = (token: string) => {
  return `Bearer ${token}`;
};

export const set_tenant_base = (url: string) => {
  TENANT_BASE = url;
  DASHBOARD_BASE = `${TENANT_BASE}/api/dashboard`;
};
