import axios from "axios";
import { AuthenticationService } from "../../../domain/src/auth/services/authenticationService";
import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "./RestApiConfig";

export class RESTAuthenticationService implements AuthenticationService {
  private readonly baseUrl = "/api";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async login(username: string, password: string): Promise<string> {
    const response = await axios.post(
      `${this.config.getSecondaryApiUrl()}${
        this.baseUrl
      }/dashboard/authenticate_admin`,
      {
        username,
        password,
      }
    );

    const { access, refresh, status } = response.data;

    if (status !== "successful") {
      throw new Error("Failed to authenticate");
    }

    this.tokenStorage.setToken(access);
    this.tokenStorage.setRefreshToken(refresh);
    return access;
  }

  async getCurrentUser() {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/me/`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }

  async logout(): Promise<void> {
    this.tokenStorage.removeToken();
    this.tokenStorage.removeRefreshToken();
  }

  async getToken(): Promise<string | null> {
    return this.tokenStorage.getToken();
  }

  private async setToken(token: string): Promise<void> {
    this.tokenStorage.setToken(token);
  }

  async isAuthenticated(): Promise<boolean> {
    return this.tokenStorage.getToken() !== null;
  }

  async refreshCurrentToken(): Promise<string> {
    const refreshToken = this.tokenStorage.getRefreshToken();
    if (!refreshToken) {
      throw new Error("No refresh token available");
    }
    const response = await axios.post(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/users/token/refresh`,
      {
        refresh: refreshToken,
      }
    );

    if (response.status !== 200) {
      throw new Error("Failed to refresh token");
    }

    await this.setToken(response.data.access);
    await this.setRefreshToken(response.data.refresh);

    return response.data.access;
  }

  async checkAdminStatus(): Promise<void> {
    const token = this.tokenStorage.getToken();

    const adminResponse = await axios.post(
      `${this.config.getSecondaryApiUrl()}${
        this.baseUrl
      }/dashboard/check_admin`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (adminResponse.data.status !== "successful") {
      throw new Error("Not authorized as admin");
    }
  }

  private async setRefreshToken(refreshToken: string): Promise<void> {
    this.tokenStorage.setRefreshToken(refreshToken);
  }
}
