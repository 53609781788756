export class Lead {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  name: string;
  attributes: {
    [key: string]: string | number | Date | string[] | null;
  };
  createdAt: string;
  updatedAt: string;
  lastWorkoutDate: string | null;
  workoutCount: number;
  registrationStatus: 'anonymous' | string;
  source: string;
  status: string;

  constructor(data: any) {
    this.id = data.id;
    this.firstName = data.first_name;
    this.lastName = data.last_name;
    this.email = data.email;
    this.phoneNumber = data.phone_number;
    this.name = data.name;
    this.attributes = data.attributes;
    this.createdAt = data.created_at;
    this.updatedAt = data.updated_at;
    this.lastWorkoutDate = data.last_workout_date;
    this.workoutCount = data.workout_count;
    this.registrationStatus = data.registration_status;
    this.source = data.source;
    this.status = data.status;
  }

  static fromJson(json: any): Lead {
    return new Lead(json);
  }
}

