export const BASE_ROUTE = "";

export const ROUTES = {
  LOGIN: {
    relative: "/login",
    complete: BASE_ROUTE + "/login",
  },
  REGISTER: {
    relative: "/",
    complete: BASE_ROUTE + "/",
  },
  HOME: {
    relative: "/",
    complete: BASE_ROUTE + "/",
  },
  EDIT_PROFILE: {
    relative: "/edit-profile",
    complete: BASE_ROUTE + "/edit-profile",
  },
  WORKOUTS: {
    relative: "/program/:program_id/*",
    complete: BASE_ROUTE + "/program/:program_id",
  },
  SPECIFIC_WORKOUT: {
    relative: "/:workout_id",
    complete: BASE_ROUTE + "/program/:program_id/:workout_id",
  },
  ADMIN_HOME: {
    relative: "/",
    complete: BASE_ROUTE + "/",
  },
  ADMIN_SPECIFIC_CLIENT: {
    relative: "/client",
    complete: BASE_ROUTE + "/client",
  },
  ADMIN_PROGRAMS_LIST: {
    relative: "/programs",
    complete: BASE_ROUTE + "/programs",
  },
};
