import { useNavigate } from "react-router";
import { useContext, useState } from "react";
import { WorkoutContext } from "../../../providers/admin/WorkoutProvider";
import { ROUTES } from "../../../consts/routing";
import { BackButton, ButtonContainer } from "../../../components/styles/common";
import WorkoutCard from "../../../components/common/WorkoutCard";
import EditWorkout from "../../../components/common/EditWorkout";

const SpecificWorkout = () => {
  const workoutContext = useContext(WorkoutContext);

  const [edit, setEdit] = useState<boolean>(false);

  const navigate = useNavigate();

  if (workoutContext.selectedWorkout === null) {
    navigate(ROUTES.HOME.complete);
    return <></>;
  }

  return (
    <>
      {!edit ? (
        <WorkoutCard
          onEditClick={() => setEdit(() => !edit)}
          workout={workoutContext.selectedWorkout}
        />
      ) : (
        <EditWorkout
          onSubmit={(title, text) => {
            workoutContext.editSelectedWorkout(
              text,
              title,
              workoutContext.selectedWorkout!.id
            );
          }}
          workout={workoutContext.selectedWorkout}
        />
      )}
    </>
  );
};

export default SpecificWorkout;
