import { ChangeEvent } from "react";
import { FormGroup, Input, Label, Select } from "../styles/common";

type PropsType = {
  formType: "text" | "number" | "select";
  options?: string[];
  onChangeWithValue?: (value: string) => void;
  onInputElementChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onSelectElementChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  name: string;
  value: string | number;
  label: string;
  readonly?: boolean;
};

const FormElement = (props: PropsType) => {
  if (props.formType === "number" || props.formType === "text") {
    return (
      <div className="grid grid-cols-3 gap-4 items-center">
        <label className="text-sm font-medium text-gray-300">
          {props.label}
        </label>
        <div className="col-span-2">
          <input
            readOnly={props.readonly}
            type={props.formType}
            name={props.name}
            value={props.value}
            id={props.name}
            onChange={(event) => {
              if (props.onInputElementChange) {
                props.onInputElementChange(event);
              } else if (props.onChangeWithValue) {
                props.onChangeWithValue(event.target.value);
              }
            }}
            className="w-full bg-gray-700 text-gray-100 border border-gray-600 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="grid grid-cols-3 gap-4 items-center">
        <label className="text-sm font-medium text-gray-300">
          {props.label}
        </label>
        <div className="col-span-2">
          <select
            name={props.name}
            value={props.value}
            id={props.name}
            onChange={(event) => {
              if (props.onSelectElementChange) {
                props.onSelectElementChange(event);
              } else if (props.onChangeWithValue) {
                props.onChangeWithValue(event.target.value);
              }
            }}
            className="w-full bg-gray-700 text-gray-100 border border-gray-600 rounded px-3 py-2 focus:outline-none focus:border-blue-500"
          >
            {props.options?.map((value, idx) => {
              return (
                <option key={idx} value={value}>
                  {value}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
};

export default FormElement;
