import { useContext, useState } from "react";
import { ProgramContext } from "../../providers/admin/ProgramProvider";
import {
  AppGrid,
  AppIcon,
  ButtonContainer,
  CardContainer,
  CardTitle,
  EditButton,
  EditButtonContainerTopLeft,
  EditButtonTopRight,
} from "../../components/styles/common";
// import NewProgramForm from "./NewProgramForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDumbbell, faClose, faAdd } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router";
import { ROUTES } from "../../consts/routing";
import { ClientContext } from "../../providers/admin/ClientProvider";
import { Dumbbell, Plus, X } from "lucide-react";
import { motion } from "framer-motion";
import NewProgramForm from "../../components/common/NewProgramForm";
import BackButton from "../../components/common/BackButton";
import Loader from "../../components/common/Loader";

const ProgramList = () => {
  const programContext = useContext(ProgramContext);
  const clientProvider = useContext(ClientContext);
  let [showForm, setShowForm] = useState<boolean>(false);
  let navigate = useNavigate();

  if (programContext.loading) {
    return <Loader />;
  }
  return (
    <>
      <BackButton />
      <div className="w-full max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 flex flex-row items-center justify-between border-b border-gray-700">
          <h2 className="text-2xl font-bold text-white">
            Programs for {clientProvider.selectedClientProfile!.first_name}{" "}
            {clientProvider.selectedClientProfile!.last_name}
          </h2>
          <button
            className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors"
            onClick={() => setShowForm(!showForm)}
          >
            {showForm ? (
              <X className="h-5 w-5 text-white" />
            ) : (
              <Plus className="h-5 w-5 text-white" />
            )}
          </button>
        </div>
        <div className="p-6">
          {showForm && (
            <NewProgramForm
              onSubmit={async (prgramTitle) => {
                await programContext.addProgram(prgramTitle);
                setShowForm(() => !showForm);
              }}
            />
          )}
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
            {programContext.programs.map((program) => (
              <motion.div
                key={program.id}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() =>
                  navigate(
                    ROUTES.WORKOUTS.complete.replace(
                      ":program_id",
                      program.id.toString()
                    )
                  )
                }
              >
                <div className="bg-gray-700 rounded-lg p-4 cursor-pointer hover:bg-gray-600 transition-colors h-full flex flex-col items-center justify-center">
                  <Dumbbell className="h-12 w-12 mb-2 text-blue-400" />
                  <h3 className="text-center font-medium text-white">
                    {program.title}
                  </h3>
                </div>
              </motion.div>
            ))}
          </div>
          {programContext.programs.length === 0 && (
            <p className="text-center text-gray-400 mt-4">
              No Programs currently made
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default ProgramList;
