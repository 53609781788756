import React, { useContext, useState } from "react";
import styled from "styled-components";
import {
  CardContainer,
  CardTitle,
  FormGroup,
  Input,
  Label,
  SubmitButton,
} from "../styles/common";
import { ProgramContext } from "../../providers/ProgramProvider";

type PropsType = {
  onSubmit?: (programTitle: string) => Promise<void>;
};

const NewProgramForm = ({ onSubmit }: PropsType) => {
  const [programTitle, setProgramTitle] = useState("");

  const handleSubmit = (e: React.FormEvent) => {
    submitForm();
  };

  const submitForm = async () => {
    if (onSubmit) {
      onSubmit(programTitle);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-6 p-4 bg-gray-700 rounded-lg">
      <h3 className="text-lg font-semibold mb-2 text-white">Add New Program</h3>
      <div className="flex items-center">
        <input
          type="text"
          value={programTitle}
          onChange={(e) => setProgramTitle(e.target.value)}
          placeholder="Enter program title"
          className="flex-grow mr-2 px-3 py-2 bg-gray-600 text-white rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          type="submit"
          className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
        >
          Add
        </button>
      </div>
    </form>
  );
};

export default NewProgramForm;
