import axios from "axios";
import { createContext, ReactNode, useEffect, useState } from "react";
import { ADMIN_ID } from "../../consts/admin_id";
import {
  BASE_URL,
  set_tenant_base
} from "../../consts/api_endpoint";
import { useDi } from "../../DiContext";

type ConfigType = {
  apiUrl: string;
};

type ApiUrlContextType = {
  register: (apiKey: string) => Promise<void>;
  loading: boolean;
  submitted: boolean;
  appConfig: ConfigType;
};

export const ApiUrlContext = createContext<ApiUrlContextType>({
  register: async () => {},
  loading: false,
  submitted: false,
  appConfig: {
    apiUrl: "",
  },
});

const ApiUrlProvider = (props: { children: ReactNode }) => {
  let [loading, setLoading] = useState<boolean>(false);
  let [submitted, setSubmitted] = useState<boolean>(false);
  let [appConfig, setAppConfig] = useState<ConfigType>({ apiUrl: "" });
  let { restApiConfig } = useDi();

  const checkRegistration = async () => {
    const url = new URL(window.location.href);
    let domain = url.hostname;

    let subdomains = domain.split(".");

    if (subdomains[0].includes(ADMIN_ID)) {
      subdomains[0] = subdomains[0].replace(ADMIN_ID, "");
    }

    domain = subdomains.join(".");

    //console.log(domain);

    setLoading(true);
    try {
      var response = await restApiConfig.checkRegistration();
      setAppConfig(response);
      set_tenant_base(restApiConfig.getSecondaryApiUrl() || "");
      setLoading(false);
    } catch (error) {
      console.error("Error fetching configuration:", error);
      throw error; // Re-throw the error for further handling
    }
  };

  const register = async (apiKey: string) => {
    setSubmitted(true);

    try {
      // Make a POST request to the WordPress API endpoint
      const response = await axios.post(
        `${BASE_URL}/authorize`,
        {
          api_key: apiKey, // Sending api_key in the body
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content type header
          },
        }
      );
      if (response.data.status === "successful") {
        set_tenant_base(response.data.apiUrl);
      } else {
        alert(response.data.error);
      }
    } catch (error) {
      alert(error);
    }

    setSubmitted(false);
  };

  useEffect(() => {
    checkRegistration();
  }, []);

  return (
    <ApiUrlContext.Provider value={{ register, loading, submitted, appConfig }}>
      {props.children}
    </ApiUrlContext.Provider>
  );
};

export default ApiUrlProvider;
