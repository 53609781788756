import { motion } from "framer-motion";
import { ArrowLeft } from "lucide-react";
import { useNavigate } from "react-router";

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={() => navigate(-1)}
      className="left-4 mt-4 ml-4 bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full focus:outline-none focus:shadow-outline"
    >
      <ArrowLeft size={24} />
    </motion.button>
  );
};

export default BackButton;
