import { createContext, useEffect, useState } from "react";
import { useDi } from "../../DiContext";
import { Lead } from "../../domain/src/leads/entities";

type ContextType = {
  leads: Lead[];
  loading: boolean;
  getLeads: () => Promise<void>;
};

export const LeadsContext = createContext<ContextType>({
  leads: [],
  loading: false,
  getLeads: async () => {},
});

type PropsType = {
  children: React.ReactElement;
};

const LeadsProvider = ({ children }: PropsType) => {
  const [leads, setLeads] = useState<Lead[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { leadsRepository } = useDi();

  const getLeads = async () => {
    setLoading(true);
    try {
      const leadsData = await leadsRepository.getLeads();
      setLeads(leadsData);
    } catch (err) {
      alert("Something went wrong. Please refresh");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLeads();
  }, []);

  return (
    <LeadsContext.Provider value={{ leads, loading, getLeads }}>
      {children}
    </LeadsContext.Provider>
  );
};

export default LeadsProvider;
