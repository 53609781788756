import { createRoot } from "react-dom/client";
import { MemoryRouter } from "react-router-dom";
import AdminApp from "./AdminApp";
import "./index.css";
import ApiUrlProvider from "./providers/common/ApiUrlProvider";
import { DiProvider } from "./DiContext";

const rootContainer = document.getElementById("root");

if (rootContainer) {
    const root = createRoot(rootContainer);
    root.render(
        <MemoryRouter>
           <DiProvider>
            <ApiUrlProvider>
                <AdminApp/>
            </ApiUrlProvider>
        </DiProvider>
      </MemoryRouter>
    );

} else {
    console.log("Not here");
}
