import { faAdd, faClose, faDumbbell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppGrid,
  AppIcon,
  CardContainer,
  CardTitle,
  EditButtonContainerTopLeft,
  EditButtonTopRight,
} from "../../../components/styles/common";
// import NewWorkoutForm from "./NewWorkoutForm";
import { useContext, useState } from "react";
import { WorkoutContext } from "../../../providers/admin/WorkoutProvider";
import { useNavigate, useParams } from "react-router";
import { ROUTES } from "../../../consts/routing";
import { motion } from "framer-motion";
import { X, Plus, Dumbbell } from "lucide-react";
import Loader from "../../../components/common/Loader";
import NewWorkoutForm from "../../../components/common/NewWorkoutForm";

const AllWorkouts = () => {
  let [showForm, setShowForm] = useState<boolean>(false);
  let workoutContext = useContext(WorkoutContext);
  const { program_id } = useParams<{ program_id: string }>();
  const navigate = useNavigate();

  if (workoutContext.loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="p-4">
        <div className="w-full max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 flex flex-row items-center justify-between border-b border-gray-700">
            <h2 className="text-2xl font-bold text-white">
              Program name: {workoutContext.program.title}
            </h2>
            <button
              className="p-2 rounded-full bg-gray-700 hover:bg-gray-600 transition-colors"
              onClick={() => setShowForm(!showForm)}
            >
              {showForm ? (
                <X className="h-5 w-5 text-white" />
              ) : (
                <Plus className="h-5 w-5 text-white" />
              )}
            </button>
          </div>
          <div className="p-6">
            {!showForm && (
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                {workoutContext.workouts.map((workout) => (
                  <motion.div
                    key={workout.id}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => {
                      workoutContext.selectWorkout(workout);
                      navigate(
                        ROUTES.SPECIFIC_WORKOUT.complete
                          .replace(
                            ":program_id",
                            workoutContext.program.id.toString()
                          )
                          .replace(":workout_id", workout.id.toString())
                      );
                    }}
                  >
                    <div className="bg-gray-700 rounded-lg p-4 cursor-pointer hover:bg-gray-600 transition-colors h-full flex flex-col items-center justify-center">
                      <Dumbbell className="h-12 w-12 mb-2 text-blue-400" />
                      <h3 className="text-center font-medium text-white">
                        {workout.title}
                      </h3>
                    </div>
                  </motion.div>
                ))}
              </div>
            )}
            {workoutContext.workouts.length === 0 && (
              <p className="text-center text-gray-400 mt-4">
                You don't have any workouts currently
              </p>
            )}
          </div>
        </div>
      </div>{" "}
      {showForm ? (
        <NewWorkoutForm
          onSubmit={async (title, text) => {
            await workoutContext.addWorkout(title, text);
            setShowForm(() => !showForm);
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default AllWorkouts;
