import styled from "styled-components";

export const DashboardContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: ${({ theme }) => theme.background};
`;

export const DashboardTitle = styled.h1`
  color: ${({ theme }) => theme.text};
  text-align: center;
  font-family: "Montserrat", "Poppins", sans-serif;

  @media (max-width: 600px) {
    font-size: 24px; // Adjust font size for smaller screens
  }
`;

export const DropdownContainer = styled.div`
  margin: 20px auto;
  text-align: center;
`;

export const DropdownSelect = styled.select`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
  }

  @media (max-width: 600px) {
    width: 100%; // Full width on small screens
  }
`;

export const DashboardContent = styled.div`
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 400px; /* Adjust as needed for vertical spacing */
  flex-direction: column; /* Stack components vertically */
  padding: 20px; /* Add padding for better spacing */

  @media (max-width: 600px) {
    padding: 10px; // Less padding on small screens
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(
    145deg,
    ${({ theme }) => theme.secondary} 30%,
    ${({ theme }) => theme.primary} 90%
  );
  border-radius: 12px;
  padding: 30px;
  margin: 20px auto;
  max-width: 90%; /* Set max-width for larger screens */
  width: 90%; /* Adjusted for better responsiveness */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
`;

export const CardTitle = styled.h2`
  color: ${({ theme }) => theme.text};
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-family: "Montserrat", sans-serif;
`;

export const EditButtonContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const EditButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) => theme.primaryDark};
  }
`;

export const ProfileSection = styled.div`
  margin-bottom: 25px;
  padding: 15px;
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.background};
  width: 100%; /* Full width to take up the space */
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

export const SectionTitle = styled.h3`
  color: ${({ theme }) => theme.text};
  margin-bottom: 15px;
  font-size: 20px;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  padding-bottom: 5px;
  font-family: "Montserrat", sans-serif;
`;

export const ProfileField = styled.div`
  margin-bottom: 12px;
`;

export const FieldLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
  color: ${({ theme }) => theme.textLight};
`;

export const FieldValue = styled.span`
  color: ${({ theme }) => theme.text};
  font-weight: 500;
`;

export const CardUl = styled.ul`
  list-style-type: none;
  padding: 0;
`;

export const CardLi = styled.li`
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid ${({ theme }) => theme.border};

  &:last-child {
    border-bottom: none;
  }
`;

export const WorkoutName = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.text};
`;

export const WorkoutDate = styled.span`
  color: ${({ theme }) => theme.text};
`;
export const FormGroup = styled.div`
  margin-bottom: 15px;
  width: 100%; /* Ensure full width for responsiveness */
  display: flex; /* Add flexbox for better alignment */
  flex-direction: column; /* Stack label and input vertically */
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.text};
  font-size: 1rem; /* Modern font size */
`;

export const Input = styled.input`
  width: 100%;
  padding: 12px; /* Increased padding for a modern look */
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box; /* Ensure padding is included in total width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus effect */

  /* Placeholder styles */
  &::placeholder {
    color: ${({ theme }) =>
      theme.textLight}; /* Lighter color for placeholder */
    opacity: 0.8; /* Slightly transparent */
  }

  /* Focus effect */
  &:focus {
    border-color: ${({ theme }) =>
      theme.primary}; /* Change border color on focus */
    box-shadow: 0 0 5px ${({ theme }) => theme.primary}; /* Light shadow for focus */
    outline: none; /* Remove default outline */
  }

  @media (min-width: 768px) {
    padding: 14px; /* Adjust padding for larger screens */
    font-size: 1.1rem; /* Slightly increase font size */
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  padding: 12px; /* Increased padding for a modern look */
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box; /* Ensure padding is included in total width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus effect */

  /* Placeholder styles */
  &::placeholder {
    color: ${({ theme }) =>
      theme.textLight}; /* Lighter color for placeholder */
    opacity: 0.8; /* Slightly transparent */
  }

  /* Focus effect */
  &:focus {
    border-color: ${({ theme }) =>
      theme.primary}; /* Change border color on focus */
    box-shadow: 0 0 5px ${({ theme }) => theme.primary}; /* Light shadow for focus */
    outline: none; /* Remove default outline */
  }

  @media (min-width: 768px) {
    padding: 14px; /* Adjust padding for larger screens */
    font-size: 1.1rem; /* Slightly increase font size */
  }
`;

export const SubmitButton = styled.button`
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  border: none;
  padding: 12px 15px;
  border-radius: 8px; /* Rounded edges for the button */
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${({ theme }) =>
      theme.primaryDark}; /* Use primaryDark for hover */
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 12px; /* Increased padding for a modern look */
  border: 1px solid ${({ theme }) => theme.border};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box; /* Ensure padding is included in total width */
  transition: border-color 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for focus effect */

  /* Placeholder styles */
  &::placeholder {
    color: ${({ theme }) =>
      theme.textLight}; /* Lighter color for placeholder */
    opacity: 0.8; /* Slightly transparent */
  }

  /* Focus effect */
  &:focus {
    border-color: ${({ theme }) =>
      theme.primary}; /* Change border color on focus */
    box-shadow: 0 0 5px ${({ theme }) => theme.primary}; /* Light shadow for focus */
    outline: none; /* Remove default outline */
  }

  @media (min-width: 768px) {
    padding: 14px; /* Adjust padding for larger screens */
    font-size: 1.1rem; /* Slightly increase font size */
  }
`;

export const AppGrid = styled.div`
  display: flex;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  margin: 20px 0;
  justify-content: center; /* Center the grid items horizontally */
  flex-direction: row; /* Stack components vertically */
`;

export const AppIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.secondary};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-5px);
  }

  img {
    width: 50px;
    height: 50px;
  }

  span {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.text};
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const BackButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: grey;
  }
`;

export const EditButtonContainerTopLeft = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const EditButtonTopLeft = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

export const EditButtonTopRight = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background-color: ${({ theme }) => theme.primary};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
`;

export const Badge = styled.span`
  display: inline-block;
  padding: 0.25em 0.6em;
  font-size: 0.75rem;
  font-weight: 600;
  color: white;
  background-color: ${({ theme }) => theme.primary};
  border-radius: 12px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
`;
