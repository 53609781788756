import React, { useContext } from "react";

import { ApiUrlContext } from "./providers/common/ApiUrlProvider";
import ActivatePlugin from "./views/admin/ActivatePlugin";
import AuthProvider from "./providers/admin/AuthProvider";
import Loader from "./components/common/Loader";
import AdminRoot from "./AdminRoot";


const AdminApp: React.FC = () => {
  const apiUrlContext = useContext(ApiUrlContext);

  const render_page = () => {
    return <AdminRoot />;
  };

  if (apiUrlContext.loading) {
    return <Loader />;
  } else if (apiUrlContext.appConfig.apiUrl.length === 0) {
    return <ActivatePlugin />;
  }

  return <AuthProvider>{render_page()}</AuthProvider>;
};

export default AdminApp;
