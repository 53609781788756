import { Lead } from "../../../domain/src/leads";
import { LeadsRepository } from "../../../domain/src/leads/repositories/leadsRepository";
import { LeadsService } from "../../../domain/src/leads/services/leadsService";

export class LeadsRepositoryImpl implements LeadsRepository {
  constructor(private readonly leadsService: LeadsService) {}

  async getLeads(): Promise<Lead[]> {
    const response = await this.leadsService.getLeads(1);
    return response.leads;
  }

  async getLead(id: string): Promise<Lead> {
    return await this.leadsService.getLead(id);
  }
}
