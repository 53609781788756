import { useContext } from "react";
import { Route, Routes } from "react-router";
import Loader from "./components/common/Loader";
import LogoutButton from "./components/common/LogoutButton";
import { ROUTES } from "./consts/routing";
import { AuthContext } from "./providers/admin/AuthProvider";
import ProgramProvider from "./providers/admin/ProgramProvider";
import WorkoutProvider from "./providers/admin/WorkoutProvider";
import AdminHome from "./views/admin/AdminHome";
import Login from "./views/admin/Login";
import Programs from "./views/admin/Programs";
import SpecificClient from "./views/admin/SpecificClient";
import WorkoutRoot from "./views/admin/workout/WorkoutRoot";

const AdminRoot = () => {
  const authProvider = useContext(AuthContext);
  if (authProvider.loading) {
    return <Loader />;
  }
  if (!authProvider.authenticatedState.isAuthenticated) {
    return <Login />;
  } else {
    return (
      <div className="bg-gray-900 min-h-screen">
        {" "}
        <LogoutButton onLogout={authProvider.logout} />
        <Routes>
            <Route path={ROUTES.ADMIN_HOME.relative} element={<AdminHome />} />
            <Route
              path={ROUTES.ADMIN_SPECIFIC_CLIENT.relative}
              element={<SpecificClient />}
            />
            <Route
              path={ROUTES.ADMIN_PROGRAMS_LIST.relative}
              element={
                <ProgramProvider>
                  <Programs />
                </ProgramProvider>
              }
            />
            <Route
              path={ROUTES.WORKOUTS.relative}
              element={
                <WorkoutProvider>
                  <WorkoutRoot />
                </WorkoutProvider>
              }
            />
          </Routes>
      </div>
    );
  }
};

export default AdminRoot;
