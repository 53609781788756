import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Workout } from "../../types/workout";
import {
  CardContainer,
  EditButtonContainerTopLeft,
  EditButtonTopLeft,
  CardTitle,
  Badge,
} from "../styles/common";
import { motion } from "framer-motion";
import { Edit } from "lucide-react";

type PropsType = {
  workout: Workout;
  onEditClick?: () => void;
};
const WorkoutCard = ({ workout, onEditClick }: PropsType) => {
  return (
    <div className="p-4">
      <div className="max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="relative bg-gray-700 p-6">
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => (onEditClick ? onEditClick() : null)}
            className="absolute top-4 left-4 bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-full focus:outline-none focus:shadow-outline"
          >
            <Edit size={24} />
          </motion.button>
          <div className="absolute top-4 right-4 bg-gray-600 px-3 py-1 rounded-full">
            <span className="text-yellow-300 text-lg font-bold">
              v{workout.version}{" "}
            </span>
          </div>
          <h2 className="text-2xl font-bold text-white mb-4 mt-8 text-center">
            {workout.title}
          </h2>
        </div>
        <div className="bg-gray-800 p-6">
          <div className="text-gray-300 whitespace-pre-wrap">
            {workout.text}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkoutCard;
