import { useContext, useState } from "react";
import FormElement from "../../components/client/FormElemet";
import { ButtonContainer, EditButton } from "../../components/styles/common";
import { ApiUrlContext } from "../../providers/common/ApiUrlProvider";

const ActivatePlugin = () => {
  const [apiKey, setApiKey] = useState<string>("");
  const apiUrlContext = useContext(ApiUrlContext);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(e.target.value);
  };

  const submit = () => {
    apiUrlContext.register(apiKey);
  };
  return (
    <>
      <FormElement
        formType="text"
        name="api_key"
        onInputElementChange={handleInputChange}
        value={apiKey}
        label="Enter your API Key"
      />
      <ButtonContainer>
        <EditButton
          type="button"
          onClick={(event) => {
            event.preventDefault();
            submit();
          }}
        >
          Activate
        </EditButton>
      </ButtonContainer>
    </>
  );
};

export default ActivatePlugin;
