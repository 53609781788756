import React, { useContext, useState } from "react";
import {
  CardContainer,
  CardTitle,
  FormGroup,
  Input,
  Label,
  SubmitButton,
  TextArea,
} from "../styles/common";
import { WorkoutContext } from "../../providers/WorkoutProvider";
import { motion } from "framer-motion";
import { Dumbbell, Save } from "lucide-react";

type PropsType = {
  onSubmit?: (workoutTitle: string, workoutText: string) => Promise<void>;
};

const NewWorkoutForm = ({ onSubmit }: PropsType) => {
  const [workoutTitle, setWorkoutTitle] = useState("");
  const [workoutText, setWorkoutText] = useState("");
  const workoutContext = useContext(WorkoutContext);

  const handleSubmit = (e: React.FormEvent) => {
    submitForm();
  };

  const submitForm = async () => {
    if (onSubmit) {
      onSubmit(workoutTitle, workoutText);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      exit={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="p-4 bg-gray-900 min-h-screen"
    >
      <div className="w-full max-w-4xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
        <div className="bg-gray-800 text-white p-6">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="w-full"
          >
            <form
              onSubmit={handleSubmit}
              className="bg-gray-800 shadow-md rounded mb-4"
            >
              <div className="mb-4">
                <label
                  className="block text-gray-300 text-sm font-bold mb-2"
                  htmlFor="workoutName"
                >
                  Workout Name
                </label>
                <div className="relative">
                  <Dumbbell
                    className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={18}
                  />
                  <input
                    className="shadow appearance-none border rounded w-full py-2 pl-10 pr-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                    id="workoutName"
                    type="text"
                    placeholder="Enter workout name"
                    name="name"
                    value={workoutTitle}
                    onChange={(event) => setWorkoutTitle(event.target.value)}
                  />
                </div>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-300 text-sm font-bold mb-2"
                  htmlFor="workoutDetails"
                >
                  Workout Details
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-300 leading-tight focus:outline-none focus:shadow-outline bg-gray-700"
                  id="workoutDetails"
                  placeholder="Enter workout details"
                  rows={20}
                  name="details"
                  value={workoutText}
                  onChange={(event) => setWorkoutText(event.target.value)}
                ></textarea>
              </div>
              <div className="flex items-center justify-center">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline flex items-center"
                  type="submit"
                >
                  <Save className="mr-2" size={18} />
                  Save Workout
                </motion.button>
              </div>
            </form>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default NewWorkoutForm;
