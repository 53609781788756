import { ApiConfigStorageService } from "../../../domain/src/auth/services/apiConfigStorageService";

export class InMemoryApiConfigStorageService
  implements ApiConfigStorageService
{
  private config: {
    apiUrl: string | null;
  } = {
    apiUrl: null,
  };

  setApiConfig(config: { apiUrl: string }): void {
    this.config = config;
  }

  getApiConfig(): { apiUrl: string | null } | null {
    return this.config;
  }

  removeApiConfig(): void {
    this.config.apiUrl = null;
  }
}
