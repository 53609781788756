import { useContext } from "react";
import { ClientContext } from "../../providers/admin/ClientProvider";
import { useNavigate } from "react-router";
import { ROUTES } from "../../consts/routing";
import UserProfileCard from "../../components/common/UserProfileCard";
import {
  EditButton,
  EditButtonContainer,
} from "../../components/styles/common";
import BackButton from "../../components/common/BackButton";
import { Dumbbell, Edit2, GroupIcon } from "lucide-react";
import Loader from "../../components/common/Loader";

const SpecificClient = () => {
  const clientContext = useContext(ClientContext);
  const navigate = useNavigate();

  if (clientContext.selectedClient == null) {
    navigate(ROUTES.ADMIN_HOME.complete);
    return <></>;
  }

  if (clientContext.loading) {
    return <Loader />;
  } else if (clientContext.selectedClientProfile) {
    return (
      <>
        <BackButton />
        <UserProfileCard
          title="User profile"
          user={clientContext.selectedClientProfile}
          button={
            <div
              className="mt-8 mb-8 text-center" // Increased top margin
            >
              <button
                onClick={() => navigate(ROUTES.ADMIN_PROGRAMS_LIST.complete)}
                className="bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded inline-flex items-center transition duration-300"
              >
                {" "}
                {/* Increased padding */}
                <GroupIcon className="w-5 h-5 mr-3" />{" "}
                {/* Increased icon size and margin */}
                View Programs
              </button>
            </div>
          }
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default SpecificClient;
