import {
  AuthenticationRepository,
  AuthenticationService,
  TokenStorageService,
} from "./domain/src/auth";
import { LeadsRepository } from "./domain/src/leads/repositories/leadsRepository";
import { LeadsService } from "./domain/src/leads/services/leadsService";
import { AuthenticationRepositoryImpl } from "./infrastructure/auth/repositories/authenticationRepositoryImpl";
import { InMemoryApiConfigStorageService } from "./infrastructure/auth/services/inMemoryApiConfigStorageService";
import { LocalStorageTokenStorageService } from "./infrastructure/auth/services/localTokenStorageService";
import { RestApiConfig } from "./infrastructure/auth/services/RestApiConfig";
import { RESTAuthenticationService } from "./infrastructure/auth/services/RESTAuthenticationService";
import { LeadsRepositoryImpl } from "./infrastructure/leads/repositories/leadsRepositoryImpl";
import { RESTLeadsService } from "./infrastructure/leads/services/RESTLeadsService";

export interface Container {
  tokenStorage: TokenStorageService;
  authenticationService: AuthenticationService;
  authenticationRepository: AuthenticationRepository;
  leadsService: LeadsService;
  leadsRepository: LeadsRepository;
  restApiConfig: RestApiConfig;
}

export const createContainer = (): Container => {
  const tokenStorage = new LocalStorageTokenStorageService();
  const apiConfigStorage = new InMemoryApiConfigStorageService();
  const restApiConfig = new RestApiConfig(
    process.env.REACT_APP_API_URL || "http://localhost:3000",
    apiConfigStorage
  );
  const authenticationService = new RESTAuthenticationService(
    tokenStorage,
    restApiConfig
  );
  const authenticationRepository = new AuthenticationRepositoryImpl(
    authenticationService
  );
  const leadsService = new RESTLeadsService(tokenStorage, restApiConfig);
  const leadsRepository = new LeadsRepositoryImpl(leadsService);
  return {
    tokenStorage,
    authenticationService,
    authenticationRepository,
    leadsService,
    leadsRepository,
    restApiConfig,
  };
};
