import { useContext } from "react";
import { LeadsContext } from "../../providers/admin/LeadsProvider";
import Loader from "../../components/common/Loader";

const ViewLeads = () => {
  const leadsProvider = useContext(LeadsContext);

  if (leadsProvider.loading) return <Loader />;

  return (
    <div className="max-w-7xl mx-auto bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <div className="bg-gray-700 p-6">
        <h2 className="text-2xl font-bold text-white mb-4 text-center">
          Your Leads (Total: {leadsProvider.leads.length})
        </h2>
      </div>
      <div className="bg-gray-900 p-6 overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-600">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                First Name
              </th>
              <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Last Name
              </th>
              <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Phone number
              </th>
              <th className="px-6 py-3 bg-gray-700 text-left text-xs font-medium text-gray-300 uppercase tracking-wider">
                Acquired on
              </th>
            </tr>
          </thead>
          <tbody className="bg-gray-800 divide-y divide-gray-600">
            {leadsProvider.leads.map((lead) => (
              <tr key={lead.id}>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {lead.firstName ?? "---"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {lead.lastName ?? "---"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {lead.email ?? "---"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {lead.phoneNumber ?? "---"}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-300">
                  {new Date(lead.createdAt).toLocaleDateString()}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewLeads;
