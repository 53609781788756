import { createContext, useContext, ReactNode } from 'react';
import { Container, createContainer } from './container';

const DiContext = createContext<Container | null>(null);

interface DiProviderProps {
    children: ReactNode;
}

export const DiProvider = ({ children }: DiProviderProps) => {
    const container = createContainer();

    return (
        <DiContext.Provider value={container}>
            {children}
        </DiContext.Provider>
    );
};

export const useDi = () => {
    const context = useContext(DiContext);
    if (!context) {
        throw new Error('useDi must be used within a DiProvider');
    }
    return context;
};
