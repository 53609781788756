import React from "react";
import { motion } from "framer-motion";
import { LogOut } from "lucide-react";

interface LogoutButtonProps {
  onLogout: () => void;
}

const LogoutButton: React.FC<LogoutButtonProps> = ({ onLogout }) => {
  return (
    <div className="fixed top-4 right-4 z-50">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onLogout}
        className="bg-gray-700 hover:bg-gray-600 text-gray-200 px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-400 flex items-center transition-colors duration-200"
      >
        <LogOut size={20} className="mr-2" />
        <span className="hidden sm:inline">Logout</span>
      </motion.button>
    </div>
  );
};

export default LogoutButton;
