import { createContext, useEffect, useState } from "react";
import axios from "axios";
import {
  DASHBOARD_BASE,
  get_jwt_access_token,
  getAuthorizationHeader,
} from "../consts/api_endpoint";
import { Workout } from "../types/workout";
import { useParams } from "react-router";
import { Program } from "../types/program";

// Define the Workout type based on your serializer

type ContextType = {
  workouts: Workout[];
  program: Program;
  loading: boolean;
  addWorkout: (title: string, text: string) => Promise<void>;
  selectWorkout: (workout: Workout) => void;
  selectedWorkout: Workout | null;
  editSelectedWorkout: (
    text: string,
    title: string,
    id: number
  ) => Promise<void>;
};

export const WorkoutContext = createContext<ContextType>({
  workouts: [],
  program: { id: 0, title: "" },
  loading: false,
  addWorkout: async () => {},
  selectWorkout: () => {},
  selectedWorkout: null,
  editSelectedWorkout: async () => {},
});

type PropsType = {
  children: React.ReactElement;
};

const WorkoutProvider = ({ children }: PropsType) => {
  let [workouts, setWorkouts] = useState<Workout[]>([]);
  let [loading, setLoading] = useState<boolean>(false);
  let [selectedWorkout, setSelectedWorkout] = useState<Workout | null>(null);
  let [program, setProgram] = useState<Program>({ id: 0, title: "" });

  let { program_id } = useParams<{ program_id: string }>();

  const fetchWorkouts = async () => {
    setLoading(true);

    try {
      let response = await axios.get(
        `${DASHBOARD_BASE}/user_workouts?program_id=${program_id}`,
        {
          headers: {
            Authorization: getAuthorizationHeader(get_jwt_access_token()),
          },
        }
      );

      let { data } = response;

      if (data.status === "successful") {
        setWorkouts(data.workouts);
        setProgram(data.program);
        setLoading(false);
      }
    } catch (err) {
      alert("Something went wrong. Please refresh");
      setLoading(false);
    }
  };

  const addWorkout = async (title: string, text: string) => {
    setLoading(true);
    try {
      let { data } = await axios.post(
        `${DASHBOARD_BASE}/user_workouts`,
        {
          program_id,
          title,
          text,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(get_jwt_access_token()),
          },
        }
      );
      if (data.status === "successful") {
        setWorkouts([...workouts, data.workout]);
        setLoading(false);
      } else {
        alert(data.error);
        setLoading(false);
      }
    } catch (err) {
      alert("Please try again");
      setLoading(false);
    }
  };

  const selectWorkout = (workout: Workout) => {
    setSelectedWorkout(workout);
  };

  const editSelectedWorkout = async (
    text: string,
    title: string,
    id: number
  ) => {
    setLoading(true);
    try {
      let response = await axios.patch(
        `${DASHBOARD_BASE}/user_workouts`,
        {
          id,
          text,
          title,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(get_jwt_access_token()),
          },
        }
      );

      console.log(response.data);

      if (response.data.status === "successful") {
        setSelectedWorkout(response.data.workout);
        let newList = workouts.filter((val) => val.id !== id);
        setWorkouts([...newList, response.data.workout]);
        setLoading(false);
      } else {
        alert(response.data.error);
        setLoading(false);
      }
    } catch (err) {
      alert("Please try again");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkouts();
  }, []);

  return (
    <WorkoutContext.Provider
      value={{
        workouts,
        loading,
        addWorkout,
        selectWorkout,
        selectedWorkout,
        program,
        editSelectedWorkout,
      }}
    >
      {children}
    </WorkoutContext.Provider>
  );
};

export default WorkoutProvider;
