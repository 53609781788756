import { createContext, useEffect, useState } from "react";
import axios from "axios";
import { Client } from "../../types/client";
import {
  DASHBOARD_BASE,
  get_jwt_access_token,
  getAuthorizationHeader,
} from "../../consts/api_endpoint";
import User from "../../types/user";

type ContextType = {
  clients: Client[];
  loading: boolean;
  selectedClient: Client | null;
  selectClient: (client: Client) => void;
  selectedClientProfile: User | null;
  //   addClient: (name: string, email: string) => Promise<void>;
};

export const ClientContext = createContext<ContextType>({
  clients: [],
  loading: false,
  selectedClient: null,
  selectedClientProfile: null,
  selectClient: () => {},
  //   addClient: async () => {},
});

type PropsType = {
  children: React.ReactElement;
};

const ClientProvider = ({ children }: PropsType) => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [selectedClientProfile, setSelectedClientProfile] =
    useState<User | null>(null);

  const fetchClients = async () => {
    setLoading(true);

    try {
      const response = await axios.get(`${DASHBOARD_BASE}/admin_get_clients`, {
        headers: {
          Authorization: getAuthorizationHeader(get_jwt_access_token()),
        },
      });
      // console.log(response.data);
      const { data } = response;
      setClients(data.clients);
      setLoading(false);
    } catch (err) {
      alert("Something went wrong. Please refresh");
      setLoading(false);
    }
  };

  const selectClient = (client: Client) => {
    setSelectedClient(client);
    fetchProfileforSelectedClient();
  };

  useEffect(() => {
    fetchProfileforSelectedClient();
  }, [selectedClient]);

  const fetchProfileforSelectedClient = async () => {
    if (selectedClient == null) {
      return;
    }

    try {
      // Make a GET request to the WordPress REST API
      setLoading(true);
      const response = await axios.get(`${DASHBOARD_BASE}/admin_get_clients`, {
        params: {
          id: selectedClient.id,
        },
        headers: {
          Authorization: getAuthorizationHeader(get_jwt_access_token()),
        },
      });

      // Check the response status
      if (response.data.status === "successful") {
        setSelectedClientProfile(response.data.userprofile);
        setLoading(false);
      } else {
        alert("Please refresh");
        setLoading(false);
      }
    } catch (error) {
      alert("Please refresh");
      setLoading(false);
      // Handle any additional errors from the API call (e.g., network issues)
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <ClientContext.Provider
      value={{
        clients,
        loading,
        selectedClient,
        selectClient,
        selectedClientProfile,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientProvider;
