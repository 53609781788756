import { useNavigate } from "react-router";
import { ROUTES } from "../../consts/routing";
import User from "../../types/user";
import {
  CardContainer,
  CardTitle,
  EditButton,
  EditButtonContainer,
  FieldLabel,
  FieldValue,
  SectionTitle,
} from "../styles/common";
import React from "react";
import { motion } from "framer-motion";
import {
  User as UserIcon,
  Activity,
  Dumbbell,
  Clock,
  Edit2,
} from "lucide-react";

type PropsType = {
  user: User;
  title: string;
  button?: React.ReactElement;
};

const ProfileSection: React.FC<{
  title: string;
  icon: React.ReactNode;
  children: React.ReactNode;
}> = ({ title, icon, children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
    className="mb-8 bg-gray-800 rounded-lg shadow-md overflow-hidden" // Increased bottom margin
  >
    <div className="px-6 py-5 sm:px-8 bg-gray-700">
      {" "}
      {/* Increased padding */}
      <h3 className="text-lg leading-6 font-medium text-gray-100 flex items-center">
        {React.cloneElement(icon as React.ReactElement, {
          className: "w-5 h-5 mr-3",
        })}{" "}
        {/* Increased icon margin */}
        {title}
      </h3>
    </div>
    <div className="px-6 py-6 sm:p-8 space-y-4">
      {" "}
      {/* Increased padding and added vertical spacing */}
      {children}
    </div>
  </motion.div>
);

const ProfileField: React.FC<{ label: string; value: string }> = ({
  label,
  value,
}) => (
  <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
    <dt className="text-sm font-medium text-gray-400">{label}</dt>
    <dd className="mt-1 text-sm text-gray-200 sm:mt-0 sm:col-span-2">
      {value}
    </dd>
  </div>
);

const UserProfileCard = ({ user, title, button }: PropsType) => {
  const navigate = useNavigate();

  return (
    <div className="p-6 bg-gray-900 min-h-screen">
      <div className="max-w-4xl mx-auto">
        {" "}
        {/* Increased max-width and padding */}
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-3xl font-bold mb-8 text-center text-gray-100" // Increased bottom margin
        >
          {title}
        </motion.h1>
        {button}
        <ProfileSection title="Personal Information" icon={<UserIcon />}>
          <ProfileField label="Username" value={user.username} />
          <ProfileField label="Age" value={`${user.age} Years old`} />
          <ProfileField label="Gender" value={user.gender} />
          <ProfileField label="Height" value={user.height} />
          <ProfileField label="Weight" value={`${user.weight} lbs`} />
          <ProfileField
            label="Medical Clearance"
            value={user.medical_clearance}
          />
          <ProfileField
            label="Current Injuries or Limitations"
            value={user.injuries}
          />
        </ProfileSection>
        <ProfileSection title="Fitness Profile" icon={<Activity />}>
          <ProfileField label="Fitness Level" value={user.fitness_level} />
          <ProfileField
            label="Current Activity Level"
            value={user.activity_level}
          />
          <ProfileField label="Fitness Goal" value={user.goal} />
          <ProfileField
            label="Intensity Level Preference"
            value={user.intensity}
          />
          <ProfileField label="Exercise Modality" value={user.modality} />
          <ProfileField label="Motivation Type" value={user.motivation_type} />
        </ProfileSection>
        <ProfileSection title="Workout Parameters" icon={<Dumbbell />}>
          <ProfileField label="Duration" value={user.duration} />
          <ProfileField label="Types of Sets" value={user.types_of_sets} />
          <ProfileField label="Available Equipment" value={user.equipment} />
          <ProfileField
            label="Workout Environment"
            value={user.workout_environment}
          />
        </ProfileSection>
        <ProfileSection title="Additional Considerations" icon={<Clock />}>
          <ProfileField
            label="Preferred Workout Time"
            value={user.preferred_workout_time}
          />
          <ProfileField label="Sleep Quality" value={user.sleep_quality} />
          <ProfileField
            label="Dietary Preferences"
            value={user.dietary_preferences}
          />
          <ProfileField label="Recovery Needs" value={user.recovery_needs} />
          {user.recovery_needs === "other" && (
            <ProfileField
              label="Other Recovery Needs"
              value={user.recovery_needs_other}
            />
          )}
        </ProfileSection>
      </div>
    </div>
  );
};

export default UserProfileCard;
