import axios from "axios";
import { LeadsService } from "../../../domain/src/leads/services/leadsService";
import { Lead } from "../../../domain/src/leads/entities";

import { TokenStorageService } from "../../../domain/src/auth/services/tokenStorageService";
import { IRestApiConfig } from "../../auth/services/RestApiConfig";
import {
  LeadFilterOptions,
  LeadsPaginatedResponse,
} from "../../../domain/src/leads/repositories/contactsRepository";

export class RESTLeadsService implements LeadsService {
  private readonly baseUrl = "/api/crm/contacts";

  constructor(
    private readonly tokenStorage: TokenStorageService,
    private readonly config: IRestApiConfig
  ) {}

  async getLeads(
    page: number,
    options?: LeadFilterOptions
  ): Promise<LeadsPaginatedResponse> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}?`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          ...options,
        },
      }
    );

    return {
      leads: response.data.results.map((lead: any) => Lead.fromJson(lead)),
      total: response.data.count,
      page: page,
      totalPages: Math.ceil(response.data.count / 10),
      hasMore: response.data.next !== null,
    };
  }

  async getLead(id: string): Promise<Lead> {
    const token = this.tokenStorage.getToken();
    const response = await axios.get(
      `${this.config.getSecondaryApiUrl()}${this.baseUrl}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return Lead.fromJson(response.data);
  }
}
