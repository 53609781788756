import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AnimatePresence, motion} from "framer-motion";
import {X} from "lucide-react";
import React, {useState} from "react";

export type OptionType = {
    component: React.ReactNode;
    image: IconDefinition;
    title: string;
};

type PropsType = {
    options: OptionType[];
};

const AppContent = ({option}: { option: OptionType }) => {
    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{opacity: 0}}
            transition={{duration: 0.3}}
            className="w-full mx-auto p-4 overflow-y-auto"
        >
            {option.component}
        </motion.div>
    );
};

const AppView = ({options}: PropsType) => {
    const [activeOption, setActiveOption] = useState<OptionType | null>(null);

    return (
        <div className="bg-gray-900 text-white min-h-screen flex items-center justify-center">
            <AnimatePresence mode="wait">
                {activeOption ? (
                    <motion.div
                        key="app-view"
                        initial={{opacity: 0, scale: 0.8}}
                        animate={{opacity: 1, scale: 1}}
                        exit={{opacity: 0, scale: 0.8}}
                        transition={{duration: 0.3}}
                        className="fixed inset-0 bg-gray-800 flex flex-col"
                    >
                        <motion.div
                            className="bg-gray-700 p-4 flex items-center"
                            initial={{y: -50}}
                            animate={{y: 0}}
                            transition={{
                                delay: 0.1,
                                type: "spring",
                                stiffness: 260,
                                damping: 20,
                            }}
                        >
                            <button
                                onClick={() => setActiveOption(null)}
                                className="text-white hover:text-gray-300 transition-colors duration-200"
                            >
                                <X size={24}/>
                            </button>
                            <h2 className="text-xl font-bold ml-4">{activeOption.title}</h2>
                        </motion.div>
                        <div className="flex-grow flex flex-col items-center justify-center overflow-hidden">
                            <AppContent option={activeOption}/>
                        </div>
                    </motion.div>
                ) : (
                    <motion.div
                        key="app-grid"
                        initial={{opacity: 0, y: 50}}
                        animate={{opacity: 1, y: 0}}
                        exit={{opacity: 0, y: 50}}
                        transition={{duration: 0.3}}
                        className="grid grid-cols-2 sm:grid-cols-3 gap-6 p-6"
                    >
                        {options.map((option, idx) => (
                            <motion.button
                                key={idx}
                                whileHover={{scale: 1.05}}
                                whileTap={{scale: 0.95}}
                                onClick={() => setActiveOption(option)}
                                className="bg-gray-800 hover:bg-gray-700 rounded-xl p-6 flex flex-col items-center justify-center transition-colors duration-200"
                            >
                <span className="text-4xl mb-2">
                  <FontAwesomeIcon icon={option.image}/>
                </span>
                                <span className="font-medium">{option.title}</span>
                            </motion.button>
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};

export default AppView;
