import { createContext, useContext, useEffect, useState } from "react";
import { Program } from "../../types/program";
import axios from "axios";
import {
  DASHBOARD_BASE,
  get_jwt_access_token,
  getAuthorizationHeader,
} from "../../consts/api_endpoint";
import { ClientContext } from "./ClientProvider";

type ContextType = {
  programs: Program[];
  loading: boolean;
  addProgram: (title: string) => Promise<void>;
};

export const ProgramContext = createContext<ContextType>({
  programs: [],
  loading: false,
  addProgram: async () => {},
});

type PropsType = {
  children: React.ReactElement;
};
const ProgramProvider = ({ children }: PropsType) => {
  let [programs, setPrograms] = useState<Program[]>([]);
  let [loading, setLoading] = useState<boolean>(false);

  let clientContext = useContext(ClientContext);

  const fetchPrograms = async () => {
    setLoading(true);

    try {
      let response = await axios.get(`${DASHBOARD_BASE}/admin_get_programs`, {
        params: {
          user_id: clientContext.selectedClientProfile!.id,
        },
        headers: {
          Authorization: getAuthorizationHeader(get_jwt_access_token()),
        },
      });

      let { data } = response;

      if (data.status === "successful") {
        setPrograms(data.programs);
        setLoading(false);
      }
    } catch (err) {
      alert("Something went wrong. Please refresh");
    }
  };

  const addProgram = async (title: string) => {
    setLoading(true);
    console.log(title);
    try {
      let { data } = await axios.post(
        `${DASHBOARD_BASE}/admin_get_programs`,
        {
          title,
          user_id: clientContext.selectedClientProfile!.id,
        },
        {
          headers: {
            Authorization: getAuthorizationHeader(get_jwt_access_token()),
          },
        }
      );
      if (data.status === "successful") {
        setPrograms([...programs, data.program]);
        setLoading(false);
      } else {
        alert(data.error);
        setLoading(false);
      }
    } catch (err) {
      alert("Please try again");
    }
  };

  useEffect(() => {
    fetchPrograms();
  }, []);

  return (
    <ProgramContext.Provider value={{ programs, loading, addProgram }}>
      {children}
    </ProgramContext.Provider>
  );
};

export default ProgramProvider;
