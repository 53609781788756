import {faSearch,} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import AppView, {OptionType} from "../../components/common/AppView";
import LeadsProvider from "../../providers/admin/LeadsProvider";
import ViewLeads from "./ViewLeads";

const AdminHome: React.FC = () => {
    // Initial state is null, meaning no app is selected
    const options: OptionType[] = [
        {
            component: (
                <LeadsProvider>
                    <ViewLeads/>
                </LeadsProvider>
            ),
            image: faSearch,
            title: "Your Leads",
        },
    ];

    return <AppView options={options}/>;
};

export default AdminHome;
