import { useContext, useState } from "react";
import { WorkoutContext } from "../../../providers/admin/WorkoutProvider";
// Assume you have a similar form component for workouts
import { Route, Routes, useNavigate } from "react-router";
import AllWorkouts from "./AllWorkouts";
import { ROUTES } from "../../../consts/routing";
import SpecificWorkout from "./SpecificWorkout";
import BackButton from "../../../components/common/BackButton";
import Loader from "../../../components/common/Loader";

const WorkoutRoot = () => {
  const workoutContext = useContext(WorkoutContext);

  if (workoutContext.loading) {
    return <Loader />;
  }

  return (
    <>
      <BackButton />

      <Routes>
        <Route path="/" element={<AllWorkouts />} />
        <Route
          path={ROUTES.SPECIFIC_WORKOUT.relative}
          element={<SpecificWorkout />}
        />
      </Routes>
    </>
  );
};

export default WorkoutRoot;
